import BrandBox from '~/themes/base/components/content/BrandBox'

export default {
  extends: BrandBox,
  data() {
    return {
      image: {
        width: 160,
      },
    }
  },
}
